<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>휴면 해제 완료</h3>
            <div class="single-file-content">
                <p>회원님의 계정이 휴면 해제되었습니다.</p>
                <p>어플레이즈의 모든 서비스를 정상적으로 이용할 수 있습니다.</p>
                <p>[확인] 버튼을 클릭하시면 로그인 화면으로 이동합니다.</p>
            </div>
            <div class="single-file-btn">
                <p id="color-text" @click="complete">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  methods: {
    complete () {
      this.$emit('closePopup');
    }
  }
};
</script>

<style scoped>
  .single-file-content p:first-child { margin-bottom: 8px; }
</style>
