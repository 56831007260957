<template>
    <div id="email_none" class="email_modal modal1">
        <div class="email_modal_box modal_box">
            <div class="modal_close_btn" @click="$emit('closeResendEmail')">
                <img src="/media/img/close_btn.png" alt="" />
            </div>
            <h3>미인증 계정</h3>
            <p>
                가입하신 메일주소의 메일함에서 인증하기 버튼을 클릭 후 재 로그인
                해주세요.
            </p>
            <button @click="sendEmail()">인증 메일 재발송</button>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import axios from "axios";

export default defineComponent({
  name: 'CertificationEmail',
  props: {
    email: String
  },
  methods: {
    sendEmail () {
      var toMail = this.email;

      axios
        .post("/api/user/getAuthEmail", { toMail })
        .then(res => {
          // console.log(res.data.result);
          this.$emit('closeResendEmail');
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
});
</script>
