<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <p style="margin-bottom: 0px;padding-bottom: 16px;color: #fff;">아이디 또는 비밀번호를 10회 잘못 입력하여<br>로그인이 차단되었습니다</p>
            <p style="margin-bottom: 0px;padding-bottom: 16px;color: #fff;">{{ time }}분 후에 다시 시도해 주세요</p>
            <div class="single-file-check">
                <p style="margin-bottom: 0px;" @click="$emit('closeModal')">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: 'loginBlockPopup',

  props: {
    blockTime: String
  },

  data () {
    return {
      time: null
    };
  },

  mounted () {
    this.setTime();
  },

  methods: {
    setTime () {
      const startDate = dayjs(Date.now()).format('YYYY-MM-DD HH:mm');
      const endDate = dayjs(this.blockTime).add(15, 'minute').format('YYYY-MM-DD HH:mm');

      const diff = parseInt(dayjs(endDate).diff(startDate, 'minute'));
      console.log('diff', diff);

      this.time = diff;
    }
  }
};
</script>
