<template>
    <label for="chk6">
        <input id="chk6" type="checkbox" :value="checked" @input="$emit('input', !checked)" />
        <span class="check" :class="{ on: checked }"></span>
    </label>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "checkbox",
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    label: String
  },
  model: {
    prop: 'checked',
    event: 'input'
  }
});
</script>

<style scoped>
label {
  height: 24px;
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  display: none;
}

span.check {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('/media/img/icon_check1_off.svg') no-repeat;
  background-size: 100%;
  cursor: pointer;
}

span.check.on {
  background: url('/media/img/icon_check1_on.svg') no-repeat !important;
  background-size: 100% !important;
  border: none !important;
}
</style>
